const routes = [
    {
        path: '/index',
        name: 'index',
        auth:false,
        component: () => import('../views/HomePage.vue')
      },
      {
        path: '/my',
        name: 'my',
        auth:false,
        component: () => import('../views/my/My.vue')
      },
      {
        path: '/message',
        name: 'message',
        auth:false,
        component: () => import('../views/message/Message.vue')
      },
      
      {
        path: '/category',
        name: 'category',
        auth:true,
        component: () => import('../views/Category.vue')
      },
      {
        path: '/apps',
        name: 'apps',
        auth:false,
        component: () => import('../views/apps/AppsIndex.vue')
      },
      {
        path: '/shopcart',
        name: 'shopcart',
        auth:false,
        component: () => import('../views/ShopCart.vue')
      },
      {
        path: '/center',
        name: 'center',
        auth:false,
        component: () => import('../views/Center.vue')
      },

      {
        path: '/order/add',
        name: 'addOrder',
        auth:false,
        component: () => import('../views/order/CreateOrder.vue')
      },
      {
        path: '/order/detail',
        name: 'detail',
        auth:false,
        component: () => import('../views/order/OrderDetail.vue')
      },
      {
        path: '/order/convert',
        name: 'convert',
        auth:true,
        component: () => import('../views/convert/ConvertOrder.vue')
      },
      {
        path: '/order/convert/add',
        name: 'addConvert',
        auth:true,
        component: () => import('../views/convert/CreateConvert.vue')
      },
      {
        path: '/comparison',
        name: 'comparison',
        auth:true,
        component: () => import('../views/comparison/Comparison.vue')
      },
      {
        path: '/receivingcode',
        name: 'receivingcode',
        auth:true,
        component: () => import('../views/receivingcode/ReceivingCode.vue')
      },
      {
        path: '/receivingcode/detail',
        name: 'receivingcodedetail',
        auth:true,
        component: () => import('../views/receivingcode/ReceivingDetail.vue')
      },
      {
        path: '/douyin',
        name: 'douyin',
        auth:false,
        component: () => import('../views/douyin/DouYin.vue')
      },
      {
        path: '/douyin/detail',
        name: 'douyindetail',
        auth:false,
        component: () => import('../views/douyin/DouYinDetail.vue')
      },
      {
        path: '/recommend',
        name: 'recommend',
        auth:false,
        component: () => import('../views/recommend/Recommend.vue')
      },
      {
        path: '/recommend/detail',
        name: 'recommenddetail',
        auth:false,
        component: () => import('../views/recommend/RecommendDetail.vue')
      },
      {
        path: '/prepay',
        name: 'prepay',
        auth:true,
        component: () => import('../views/prepay/PrePay.vue')
      },
      {
        path: '/prepay/detail',
        name: 'prepaydetail',
        auth:true,
        component: () => import('../views/prepay/PreDetail.vue')
      },
      {
        path: '/recharge',
        name: 'recharge',
        auth:true,
        component: () => import('../views/recharge/ReCharge.vue')
      },
      {
        path: '/recharge/detail',
        name: 'rechargedetail',
        auth:true,
        component: () => import('../views/recharge/RechargeDetail.vue')
      },
      {
        path: '/prepay/balancelist',
        name: 'balancelist',
        auth:true,
        component: () => import('../views/prepay/BalanceList.vue')
      },
      {
        path: '/prepay/balancelist',
        name: 'balancelist',
        auth:false,
        component: () => import('../views/prepay/BalanceList.vue')
      },
      {
        path: '/shop',
        name: 'shop',
        auth:true,
        component: () => import('../views/setshop/SetShop.vue')
      },
      {
        path: '/shop/detail',
        name: 'shopdetail',
        auth:true,
        component: () => import('../views/setshop/ShopDetail.vue')
      },
      {
        path: '/shop/create',
        name: 'createshop',
        auth:true,
        component: () => import('../views/setshop/CreateShop.vue')
      },
      {
        path: '/coupon/mycoupon',
        name: 'mycoupon',
        auth:false,
        component: () => import('../views/coupon/MyCoupon.vue')
      },
      {
        path: '/my/secure',
        name: 'secure',
        auth:false,
        component: () => import('../views/secure/SecureIndex.vue')
      },
      {
        path: '/my/secure/changepassword',
        name: 'changepassword',
        auth:false,
        component: () => import('../views/secure/ChangePassword.vue')
      },
      {
        path: '/my/servicecenter',
        name: 'servicecenter',
        auth:false,
        component: () => import('../views/my/ServiceCenter.vue')
      },
      {
        path: '/my/bindcard',
        name: 'bindcard',
        auth:false,
        component: () => import('../views/my/BindCard.vue')
      },
      {
        path: '/my/mycard',
        name: 'mycard',
        auth:false,
        component: () => import('../views/my/MyCard.vue')
      },
      {
        path: '/my/addressbook',
        name: 'addressbook',
        auth:false,
        component: () => import('../views/my/AddressBook.vue')
      },
      {
        path: '/my/addressbook/detail/:id', 
        name: 'addressbookdetail',
        auth:false,
        component: () => import('../views/my/AddressbookDetail.vue')
      },
      {
        path: '/my/advise',
        name: 'advise',
        auth:false,
        component: () => import('../views/advise/Advise.vue')
      },
      {
        path: '/my/adviselist',
        name: 'adviselist',
        auth:false,
        component: () => import('../views/advise/AdviseList.vue')
      },
      {
        path: '/income/takecash',
        name: 'takecash',
        auth:false,
        component: () => import('../views/income/TakeCash.vue')
      },
      {
        path: '/income/takecash/detail',
        name: 'takecashdetail',
        auth:false,
        component: () => import('../views/income/TakecashDetail.vue')
      },
      {
        path: '/my/UserAgreement',
        name: 'UserAgreement',
        auth:false,
        component: () => import('../views/my/UserAgreement.vue')
      },
      {
        path: '/income/myincome',
        name: 'myincome',
        auth:false,
        component: () => import('../views/income/MyIncome.vue')
      },
      {
        path: '/income/serviceAgreement',
        name: 'serviceAgreement',
        auth:false,
        component: () => import('../views/income/ServiceAgreement.vue')
      },
      {
        path: '/office/absence',
        name: 'absence',
        auth:false,
        component: () => import('../views/office/absence/absence.vue')
      },
      {
        path: '/office/absence/detail',
        name: 'absencedetail',
        auth:false,
        component: () => import('../views/office/absence/AbsenceDetail.vue')
      },
      {
        path: '/office/outside',
        name: 'outside',
        auth:false,
        component: () => import('../views/office/outside/OutSide.vue')
      },
      {
        path: '/office/outside/detail',
        name: 'outsidedetail',
        auth:false,
        component: () => import('../views/office/outside/OutsideDetail.vue')
      },
      {
        path:'/',
        redirect:'/index', 
        auth:false,
      },
      {
        path: '/:pathMatch(.*)*',
        redirect:'/index',
        auth:false,
        // meta: {Auth: false},
        // name: "NotFound",
        // component:()=>import('../views/notfound/NotFound.vue')
      }
]

export default routes